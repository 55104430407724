#contact {
	background-image: url(../images/shape_pink_line.svg);
	background-position: right bottom;
	background-repeat: no-repeat;
}

.parallax__wrap--1 {
	background: url(../images/shape_yellow_line.svg) 0 0 / 1232px 1165px no-repeat;
}

#location {
	background: url(../images/shape_yellow_circle.svg) left 50% / 262px 342px no-repeat;
}

@include media('tablet'){
	.parallax__wrap--2 {
		background: url(../images/shape_blue_curve.svg) right bottom / 470px 1162px no-repeat;
	}
}
