
.content__slide {
	padding-top: 50px;
}

.content__slide--home {
	z-index: 2;
}

.content__slide--green {
	background: $green;
}

.content--copy {
	padding-top: 60px;

    p {
        a {
            border-bottom: 1px solid transparent;
            color: #000;
            text-decoration: none;
            @include trans(border-color);

            &:hover {
                border-color: #000;
            }
        }
    }
}

.content__features {
	margin-top: 60px;
    text-align: center;
    max-width: 900px;
    margin: 60px auto;

	li {
		background-position: center top;
		background-repeat: no-repeat;
		background-size: 32px 32px;
	    clear: both;
		font-size: 15px;
		font-weight: 400;
		display: inline-block;
		line-height: 1.4;
		margin-bottom: 6px;
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 12px;
		width: 100%;
		max-width: 200px;

		padding-top: 50px;

		&.feature--1 {
			background-image: url(../images/6-chesterfield-icon-demised-showers.svg)
		}
		&.feature--2 {
			background-image: url(../images/6-chesterfield-icon-new-refurbishment.svg)
		}
		&.feature--3 {
			background-image: url(../images/6-chesterfield-icon-commissionaire.svg)
		}
		&.feature--4 {
			background-image: url(../images/6-chesterfield-icon-air-conditioning.svg)
		}
		&.feature--5 {
			background-image: url(../images/6-chesterfield-icon-natural-light.svg)
		}
		&.feature--6 {
			background-image: url(../images/6-chesterfield-icon-raised-floors.svg)
		}
		&.feature--7 {
			background-image: url(../images/6-chesterfield-icon-passenger-lift.svg)
		}
	}

}

.content__downloads {
	margin-top: 11px;
	margin-bottom: 18px;

	li {
		font-size: 17px;
		line-height: 1.1;
		margin-bottom: 20px;
		padding-bottom: 5px;

		a {
			color: #000;
			font-size: 20px;
			font-weight: 900;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.content--black {
	background: #000;
	padding-top: 50px;

	p, li, p a {
		color: #fff!important;
	}
}

@include media('tablet'){
	.content__slide {
		padding-top: 0px;
		z-index: 1;
	}

	.content--copy {
		margin-bottom: 0px;
	}

	.horiz-slide {
		overflow: hidden;
		position: relative;
	}


	.content__features {
		li {
			margin-bottom: 40px;
		}
	}

}

@include media('desktop'){
	.content--copy {
		margin-bottom: 0px;
	}

	.content--copy {
		padding-top: 100px;
	}
}
