table {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	letter-spacing: 1px;
	margin-bottom: 30px;
	max-width: 460px;
	width: 100%;

	tr {
		border-bottom: 1px solid #000;

		td {
			border-left: 1px solid #000;
			color: #000;
			font-size: 17px;
			letter-spacing: 1px;
			line-height: 1.3;
			padding: 2px 4px;
			text-align: left;
			width: 33%;

			&:first-of-type {
				text-align: left;
				border-left: none;
			}
		}

		&.top {
			td {
				background: #000;
				color: #fff;
			}
		}
	}
}

@include media("tablet") {
	section {

		table {


	        tr {

	            td {
	                font-size: 15px;
				}
			}
		}
	}
}
