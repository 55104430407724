.content__block--floorplan {
	p {
		font-size: 18px;
	}
}

.floorplan {
	margin-bottom: 30px;
	padding: 0 20px;
}

.floorplan__list {
	li {
		font-size: 16px;
	}
}

.image--floorplan {
	display: inline-block;
	margin: 0 auto 0px!important;
	max-width: 728px;//926
}

.image__key {
	overflow: hidden;
	margin: 0 auto;
	max-width: 889px;
	position: relative;
	width: 95%;

	li {
		display: inline-block;
		font-size: 12px;
		float: left;
		line-height: 1.2;
		padding: 0px;
		width: 100%;
	}

	.image__key--text {
		padding-top: 20px;
	}

	.image__key--arrow {
		background: url(../images/north-symbol-01.svg) right 0 no-repeat;
		height: 13px;
		margin-top: 20px;
		@include hideText;

		position: absolute;
	    top: -20px;
	    right: 32px;
	}
}

@include media('small-tablet'){
	.image__key {
		li {
			width: 33.33%;
		}

		.image__key--text {
			padding-top: 0px;
		}

		.image__key--arrow {
			margin-top: 0px;

			position: static;
		    top: auto;
		    right: auto;
		}
	}

	.image--floorplan {
		display: inline-block;
		margin: 0 auto 30px!important;
	}
}

@include media('tablet'){
	.floorplan {
		padding: 0;
	}
}
