.image--map {
	display: block;
	margin: 0 auto 70px!important;
	max-width: 926px;
}

.map-key {
	margin-bottom: 110px;
	overflow: hidden;
	padding: 0 30px;

	.map-key__column {
		float: left;
		padding-right: 10px;
		width: 50%;

		&:nth-child(2n){
			padding-right: 0px;
			padding-left: 10px;
		}

		ul, img {
			margin-bottom: 40px;
		}
	}

	// li {
	// 	font-size: 12px;
	// 	line-height: 1.4;
	// 	text-align: left;
	//
	// 	ol {
	// 		list-style-type: decimal;
	// 		//list-style-position:inside;
	// 		margin-top: 10px;
	//
	// 		li {
	// 			font-weight: 300;
	// 			padding-left: 0px;
	// 			text-transform: none;
	// 		}
	// 	}
	// }
}

.key__title {
	font-weight: 700;
	letter-spacing: 2px;
	margin-bottom: 10px;
	padding-left: 50px;
	position: relative;
	text-transform: uppercase;

	&:before {
		background: #ccc;
		border-radius: 100%;
		content: "";
		height: 12px;
		position: absolute;
		top: 0px;
		left: 20px;
		width: 12px;
	}

	&.key__title--1 {
		&:before {
			background: #B19AD9;
		}
	}

	&.key__title--2 {
		&:before {
			background: #F79270;
		}
	}

	&.key__title--3 {
		&:before {
			background: #A9E0FA;
		}
	}

	&.key__title--4 {
		&:before {
			background: #FFBA5B;
		}
	}
}

@include media('small-tablet'){
	.map-key {
		margin: 0 auto;
		max-width: 924px;


		.map-key__column {
			overflow: hidden;
			padding-right: 0px;

			&:nth-child(2n){
				padding-right: 0px;
				padding-left: 0px;
			}

			ul, img {
				float: left;
				margin-right: 30px;
				width: calc(50% - 30px);

				// li {
				// 	padding: 0 20px;
				//
				// 	&.key__title {
				// 		padding-left: 50px;
				// 	}
				//
				// 	ol li {
				// 		padding-left: 0px;
				// 	}
				// }
			}
		}
	}
}

@include media('tablet'){
	.map-key {
		padding: 0px;
	}
}
