.button {
    background: transparent;
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 80px;
    padding: 13px 50px 11px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    @include trans(all);

    &:hover {
        background: #000;
        color: #fff;
    }
}

.button--scroll {
	bottom: 70px;
	left: 50%;
	background: url(../images/Arrow-down.svg) 0px 0 / 100% auto no-repeat;
	width: 34px;
	height: 18px;
	position: absolute;
	transform: translateX(-50%);
	@include hideText;
}

@include media('small-tablet'){
	.button--scroll {
		//display: block;
		bottom: 60px;
	}
}
