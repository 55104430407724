.content__block {
	clear: both;
	margin-bottom: 100px;
}

.contact__logo {
	margin-bottom: 40px;
}

.contact__logo--1 {
	height: 24px!important;
	margin-top: 23px;
}

.contact__logo--2 {
	height: 47px!important;
}

.contact__logo--3 {
	height: 34px!important;
	margin-top: 13px;
}

hr {
	height: 2px;
	background: #fff;
	width: 240px;
	border: 0px;
	margin-bottom: 32px;
}

.contact-details {
    margin-bottom: 80px;

	img {
		margin-bottom: 40px!important;
	}

	p {
		margin-bottom: 20px;
		position: relative;

		&.first {
			padding-top: 50px;

			&:before {
				content: "";
				background: #000;
				max-width: 240px;
				height: 2px;
				position: absolute;
				top: 0px;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
			}
		}


		strong {
			text-transform: uppercase;
		}

		a {
			color: #fff;

			&:hover {
				border-bottom-color: transparent;
				//text-decoration: underline;
			}
		}
	}
}

.contact-details__item {
	margin-bottom: 100px;
}

@include media('small-tablet'){

	.contact-details {
		overflow: hidden;
		margin: 0 auto;
		width: 768px;

		.contact-details__column {
			float: left;
			margin-bottom: 60px;
			overflow: hidden;
			width: 100%;
		}

		// img {
		// 	float: left;
		// 	margin-bottom: 90px!important;
		// }

		.contact__details--item {
			float: left;
			margin-right: 75px;
			width: 188px;

			&:last-of-type {
				margin-right: 0px;
			}
		}
	}

}

@include media('tablet'){
	.contact-details {
		width: 1000px;
	}
	.contact-details__item {
		float: left;
		width: 33.33%;
	}

	.contact-details__item {
		margin-bottom: 0px;
	}
}
