// Box sizing
// -------------------------------------

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

// Body styling
// -------------------------------------

body {
	background: #fff;
	background-attachment: fixed;
	background-size: cover;
		//background: #303030;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 320px;
}

// Responsive images
// -------------------------------------
.clear--left {
    clear: left;
}

.clear {
	clear: both;
}

img {
	display: block;
	// width: 100%;
	// max-width: 100%;
	vertical-align: middle;
}
