/* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/3a62ef");
// @font-face {font-family: 'Radikal-Regular';src: url('../fonts/3A62EF_0_0.eot');src: url('../fonts/3A62EF_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3A62EF_0_0.woff2') format('woff2'),url('../fonts/3A62EF_0_0.woff') format('woff'),url('../fonts/3A62EF_0_0.ttf') format('truetype');}
// @font-face {font-family: 'Radikal-Thin';src: url('../fonts/3A62EF_1_0.eot');src: url('../fonts/3A62EF_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3A62EF_1_0.woff2') format('woff2'),url('../fonts/3A62EF_1_0.woff') format('woff'),url('../fonts/3A62EF_1_0.ttf') format('truetype');}

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/384136");
@font-face {font-family: 'VanitasStencil-Regular';src: url('../../webfonts/384136_0_0.eot');src: url('../../webfonts/384136_0_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/384136_0_0.woff2') format('woff2'),url('../../webfonts/384136_0_0.woff') format('woff'),url('../../webfonts/384136_0_0.ttf') format('truetype');}



body {
	font-family: brandon-grotesque,sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
}

h2, h3, h4 {
	color: $feature;
	font-family: VanitasStencil-Regular;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 1px;
	margin-bottom: 28px;
	padding-bottom: 20px;
	position: relative;

	&:after {
		background: $highlight;
		bottom: 0;
		content: "";
		height: 2px;
		left: calc(50% - 30px);
		position: absolute;
		width: 60px;
	}

	a {
		color: $feature;
		text-decoration: none;
		@include trans(color);

		&:hover {
			color: $highlight;
		}
	}
}

h2, h3 {
	letter-spacing: 1px;
    font-size: 45px;
	line-height: 1.1;
}

h3 {
	font-size: 20px;
	margin-bottom: 11px;
}

p {
	color: $text;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 1.4;
	margin: 0 auto 22px;
	max-width: 470px;
	padding: 0 20px;
}

strong {
	font-weight: 700;
}

ul li {
	color: $text;
	letter-spacing: 1px;
	padding: 0 20px;
}

p.footnote--spec {
	font-size: 16px;
}

@include media('smallest'){
	p {
		font-size: 16px;
	}
}

@include media('tablet'){
	h2 {
	    font-size: 50px;
		margin-bottom: 28px;
	}

	h3 {
		font-size: 29px;

		&.restricted {
			font-size: 20px;
		}
	}

	p.footnote--spec {
		float: left;
		width: calc(100% - 110px)!important;
	}

	p, ul li {
		padding: 0px;
	}
}
