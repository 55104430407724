header.header {
	background: #000 url(../images/6_Chesterfield_Gardens_Headerr_Mobile.jpg) center center / cover no-repeat;
    min-height: calc(100vh - 60px);
	position: relative;
	width: 100vw;

    .header__content {

    	h1 {
			background: url(../images/6-chesterfield-logo.svg) 0 0 no-repeat;
			height: 216px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -50px;
			width: 250px;
			transform: translate(-50%, -50%);
    		@include hideText;
    	}
    }

	p {
		bottom: 80px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		left: 50%;
		letter-spacing: 2px;
		position: absolute;
		text-align: center;
		transform: translate(-50%);
		width: 100%;
	}
}

@include media("smallest") {
	header.header {
	    .header__content {
	    	h1 {
				height: 216px;
				width: 334px;
			}
		}
	}
}

@include media("small-tablet") {

    header.header {
        min-height: calc(100vh - 60px);
    }

	header.header {

	    .header__content {

	    	h1 {
				height: 306px;
				width: 472px;
	    	}
	    }

		p {
			bottom: 93px;
			max-width: 400px;
		}
	}
}

@include media("tablet") {

    header.header {
		background: #000 url(../images/6_Chesterfield_Gardens_Header_Desktop.jpg) center center / cover no-repeat;
    }
}

@media screen and (min-width: 1025px){
	header.header {
		min-height: 100vh;
	}
}

@media screen and (max-height: 495px){
	header.header {
		min-height: 495px;
	}
}
