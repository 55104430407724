.title--download {
	margin-bottom: 100px;
	padding-bottom: 50px;

	&:after {
		background: url(../images/Download-Brochure-Icon.svg) 0 0 / 100% auto no-repeat;
		bottom: 0;
		content: "";
		height: 44px;
		left: calc(50% - 22px);
		position: absolute;
		width: 44px;
		z-index: 0;
	}

	a {
		padding-bottom: 50px;
		position: relative;
		z-index: 1;
	}
}
