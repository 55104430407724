.column--wide {
	margin-left: 40px;
	//margin-bottom: 45px;
	width: calc(100% - 80px);
}

.column--narrow--2 {
	margin-bottom: 45px;
	padding: 0 40px;
}

.column--restricted {
	max-width: 911px;
	overflow: hidden;
}

.column--narrow--1 {
	width: 400px;
	margin-left: 0px!important;
}

.column--narrow--2 {
	margin-left: 0px!important;
}

@include media('small-tablet'){

	.column--narrow--2 {
		float: left;
		width: 50%;
	}

	.column--wide {
		float: left;
		margin-left: 0px;
		padding: 74px 40px 0 0;
		width: 50%;
	}

}

@include media('tablet'){

	.column--narrow--1 {
		width: 400px;
		float: left;
	}

	.column--narrow--2 {
		float: left;
		width: 510px;
	}

	.column--wide {
		padding-left: 70px;
		width: calc(100% - 510px);
	}

	.column--50--larger {
		float: left;
		width: calc(50% - 50px);

		&:first-of-type {
			margin-right: 100px;
		}
	}

}

@include media('desktop'){
	.column--narrow--2 {
		padding-left: 0px;
	}

	.column--wide {
		padding-left: 140px;
	}

}
