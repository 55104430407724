.logo--infini {
	width: 90px!important;
}

@include media('tablet'){
	.logo--infini {
		float: right;
		margin-top: 40px;
	}
}
