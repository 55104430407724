// =====================================
// Variables
// =====================================

// Media queries
// -------------------------------------

$breakpoints: (
	smallest: "only screen and (min-width: 350px)",
	mobile: "only screen and (min-width: 640px)",
	small-tablet: "only screen and (min-width: 768px)",
	tablet: "only screen and (min-width: 1024px)",
	desktop: "only screen and (min-width: 1200px)",
	large-desktop: "only screen and (min-width: 1400px)",
	largest: "only screen and (min-width: 1600px)",
	table: "only screen and (max-width: 799px)",
	hidpi: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
);

// Colours
// -------------------------------------
$feature: #AD956A;
$highlight: #F8A14C;
$text: #000000;



$green: #33E8A1;
$grey: #575757;
$black: #000;
$red: #de0e39;
$blue: #0000FC;
$pink: #ED4CFF;




/* placeholder colors */
form input::-webkit-input-placeholder {
  color: $black;
}
form input:-moz-placeholder {
  color: $black;
}
form input::-ms-input-placeholder {
  color: $black;
}
form input:-ms-input-placeholder {
  color: $black;
}

/* focus */
form input:focus::-webkit-input-placeholder {
  color: $black;
}
form input:focus:-moz-placeholder {
  color: $black;
}
form input:focus::-ms-input-placeholder {
  color: $black;
}
form input:focus:-ms-input-placeholder {
  color: $black;
}


/* mixins */
@mixin hide {
	display: none;
}

@mixin hideText {
	color: transparent;
	font: 0/0 a;
}

@mixin bdrRadius ($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin bgSize {
	-o-background-size: 100% 100%, auto;
	-moz-background-size: 100% 100%, auto;
	-webkit-background-size: 100% 100%, auto;
	background-size: 100% 100%, auto;
}

@mixin bg_size_half {
	-o-background-size: 50% auto, auto;
	-moz-background-size: 50% auto, auto;
	-webkit-background-size: 50% auto, auto;
	background-size: 50% auto, auto;
}

@mixin bgSizeWidth {
	-o-background-size: 100% auto, auto;
	-moz-background-size: 100% auto, auto;
	-webkit-background-size: 100% auto, auto;
	background-size: 100% auto, auto;
}

@mixin textShadow ($distance, $transparency, $color) {
	text-shadow: $distance $distance $transparency $color;
}

@mixin boxShadow ($distance: 10px, $strength: 5px, $color: #000000) {
	-moz-box-shadow: $distance $distance $strength $color;
	-webkit-box-shadow:  $distance $distance $strength $color;
	box-shadow:  $distance $distance $strength $color;
}

@mixin boxShadowFull ($distance1: 10px, $distance2: 10px, $strength: 5px, $color: #000000) {
	-moz-box-shadow: $distance1 $distance2 $strength $color;
	-webkit-box-shadow:  $distance1 $distance2 $strength $color;
	box-shadow:  $distance1 $distance2 $strength $color;
}

@mixin cover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

@mixin center_image {
	bottom: 0;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
}

@mixin rotate($deg) {
  -ms-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin cw {
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@mixin ccw {
  -ms-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

@mixin transTime($time) {
	transition: all $time liner;
	-moz-transition: all $time linear;
	-webkit-transition: all $time linear;
}

@mixin trans($thing) {
	transition: $thing .3s ease-in-out;
	-moz-transition: $thing .3s ease-in-out;
	-webkit-transition: $thing .3s ease-in-out;
}

@mixin diamond {
	background: #fff;
    content: "";
    display: block;
    height: 12px;
    position: absolute;
    right: -7px;
    top: 7px;
    width: 12px;
	@include rotate(45deg);
}

@mixin split($color1, $color2){
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(left, $color1 0%, $color1 50%, $color2 51%, $color2 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, $color1 0%,$color1 50%,$color2 51%,$color2 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, $color1 0%,$color1 50%,$color2 51%,$color2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin translateY {
	// left: 0px;
	// position: absolute;
	// top: 50%;
	// width: 100%;
	-ms-transform: translateY(-50%); /* IE 9 */
   	-webkit-transform: translateY(-50%); /* Safari */
    transform: translateY(-50%);
}

@mixin translateX {
	// left: 0px;
	// position: absolute;
	// top: 50%;
	// width: 100%;
	-ms-transform: translateX(-50%); /* IE 9 */
   	-webkit-transform: translateX(-50%); /* Safari */
    transform: translateX(-50%);
}

@mixin translateXY {
	// left: 0px;
	// position: absolute;
	// top: 50%;
	// width: 100%;
	-ms-transform: translate(-50%, -50%); /* IE 9 */
   	-webkit-transform: translate(-50%, -50%); /* Safari */
    transform: translate(-50%, -50%);
}

.no-overflow {
	overflow: hidden;
}

.right {
	float: right;
}

.hide {
	display: none;
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes hvr-wobble-vertical2 {
  8.32% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  16.6% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  25% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  33.4% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  41.75% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.hvr-wobble-vertical:hover,
.hvr-wobble-vertical:focus,
.hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.bounce {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.bounce-repeat {
  -webkit-animation-name: hvr-wobble-vertical2;
  animation-name: hvr-wobble-vertical2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}




/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-wobble-horizontal:hover, .hvr-wobble-horizontal:focus, .hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Pulse Grow */
@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.hvr-pulse-grow,
.hover_pulse,
.hover_pulse2 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-pulse-grow:hover, .hvr-pulse-grow:focus, .hvr-pulse-grow:active, .hover_pulse.active {
  animation-name: hvr-pulse-grow;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.hover_pulse2.active {
  animation-iteration-count: 4;
}



/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.hvr-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}


.overlay-scale {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay-scale.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}





/* Pulse */
@-webkit-keyframes cont-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes cont-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.pulse {
  -webkit-animation-name: cont-pulse;
  animation-name: cont-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}



/* Rotate */
.hvr-rotate,
.hvr-rotate2 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-rotate:hover {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hvr-rotate2 {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //cubic-bezier(.18,.06,.37,1)
}

.hvr-rotate2.active {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

// ***********************
// From https://github.com/daneden/animate.css
// ***********************
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
