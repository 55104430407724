
p.footnote {
    clear: both;
    font-size: 11px;
	font-weight:400;
	line-height: 1.2;
	letter-spacing: 0px;
	margin-bottom: 30px;
    max-width: 1122px;
	padding: 0 40px;
	width: 100%;

    a {
        border-bottom: 1px solid transparent;
		color: #000!important;
        text-decoration: none;
        @include trans(border-color);

        &:hover {
            border-color: #000;
        }
    }

	strong {
		font-weight: 700;
	}
}

#contact p.footnote {
	margin-bottom: 10px;
}


@include media('small-tablet'){

		p.footnote {
			span,
			a {
				display: inline;
				width: auto;
			}
		}

}
