// Sections
// -------------------------------------
section {
    clear: both;
	overflow: hidden;

	.inner {
		margin: 0 auto;
		max-width: 1122px;
		position: relative;
		text-align: center;

		.inner__inner {
			margin: 0 auto;
			position: relative;
			margin-bottom: 45px;
			padding: 0 40px;
		}

		img {
            //display: block;
			height: auto;
            margin-bottom: 12px;
			width: 100%;
		}
	}
}

@include media("tablet") {
	section {
		.inner {
			min-height: 100vh;
			position: relative;
			max-width: 1138px;

			.inner__inner {
				margin-bottom: 0px;
				padding: 110px 40px 0;
				padding: 61px 40px 0;
			}
		}
	}
}

@include media("desktop") {
	section {
		.inner {

			.inner__inner {
				position: static;
				padding: 61px 0px 0;
			}
		}
	}
}

@include media("large-desktop") {
	section {
		.inner {
			max-width: 1350px;
		}
	}
}

@include media("largest") {
	section {
		.inner {
			max-width: 1550px;
		}
	}
}
