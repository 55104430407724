.carousel__wrapper {
	padding-bottom: 40px;
}

.carousel {
	display: inline-block;
	margin: 30px auto 0 auto;
    position: relative;
	max-width: 948px;
	width: 100%;

	img {
		margin-bottom: 35px!important;
	}

    // ul.slick-dots {
    //     bottom: -66px;
    //     left: 63px;
    //     position: absolute;
    //     z-index: 1;
	//
    //     li {
    //         display: inline-block;
    //         margin: 0 2px 0 2px;
    //         width: 9px;
	//
    //         button {
    //             background: transparent;
    //             border: 1px solid $blue;
	// 			border-radius: 100%;
	// 			display: block;
    //             height: 9px;
    //             padding: 0px;
    //             width: 9px;
	// 			@include hideText;
    //         }
	//
	// 		&.slick-active button {
	// 			background: $blue;
	// 		}
    //     }
    // }
}

button.slick-arrow {
	border: none;
	bottom: 10px;
    cursor: pointer;
    height: 35px;
    position: absolute;
    width: 18px;
    @include hideText;
	z-index: 9999;

    &.slick-prev {
		background: url(../images/Arrow-left.svg) 0 0 no-repeat;
		left: 20px;
    }

    &.slick-next {
		background: url(../images/Arrow-right.svg) 0 0 no-repeat;
		right: 20px;
    }

    &.slick-disabled {
        cursor: default;
        opacity: 0.3;
        outline: none;
    }

}

.carousel--single {
	margin-top: 61px;
}

@include media('smallest'){
	button.slick-arrow {
		bottom: 14px;

	    // &.slick-prev {
		// 	left: 50px;
	    // }
		//
	    // &.slick-next {
		// 	right: 50px;
	    // }
	}
}

@include media('desktop'){
	button.slick-arrow {
		bottom: calc(50% + 18px);

	    &.slick-prev {
			left: -110px;
	    }

	    &.slick-next {
			right: -110px;
	    }
	}
}
